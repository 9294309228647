export const headers = [
  {
    text: 'Survey ID',
  },
  {
    text: 'Profil Pencari Kos',
  },
  {
    text: 'Nama Properti',
  },
  {
    text: 'Waktu Survei',
  },
  {
    text: 'Platform',
  },
  {
    text: 'Status',
  },
  {
    text: 'Update Terakhir',
  },
  {
    text: 'Update Oleh',
  },
  {
    text: 'Action',
  },
];

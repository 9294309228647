export default {
  data() {
    return {
      profileTableHeader: [
        {
          text: 'Survey ID',
        },
        {
          text: 'Nama Properti',
        },
        {
          text: 'Waktu Survei',
        },
        {
          text: 'Platform',
        },
        {
          text: 'Status',
        },
        {
          text: 'Update Terakhir',
        },
        {
          text: 'Updated Oleh',
        },
      ],

      breadcrumbUrl: [
        {
          name: 'Survey Tracker',
          url: '/survey-tracker',
        },
        {
          name: 'Loading...',
          url: '/#',
        },
      ],

      userProfile: [
        { label: 'User ID', content: '-' },
        { label: 'Nama Penyewa', content: '-' },
        { label: 'No. HP', content: '-' },
        { label: 'Email', content: '-' },
      ],
    };
  },
};

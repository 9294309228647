<template>
  <div class="survey-profile">
    <bg-breadcrumb :links="breadcrumbUrl" class="survey-profile__bread-crumb" />
    <card-tenant-info :profile-data="userProfile" />
    <div class="survey-profile__history-title">
      <bg-text size="heading-3">Riwayat Survey Kos</bg-text>
    </div>
    <div class="survey-profile__wrapper">
      <survey-table-content-list
        :is-load-data="isLoadData"
        :table-content="listTrackerSurvey"
        :custom-headers="profileTableHeader"
        :is-enable-hover-effect="true"
        @on-row-clicked="handleShowCardDetail"
        @on-click-page="updateSurveyDataBasedPage"
        @on-click-sort-survey-time="handleSortSurveyTime"
        @on-click-sort-update-survey="handleSortUpdateSurveyTime"
      />
      <survey-detail-card
        v-if="isShowCardDetail"
        :row-number="rowNumber.toString()"
        :revision-id="revisionId.toString()"
        :survey-id="trackerSurveyId.toString()"
      />
    </div>
  </div>
</template>

<script>
import { BgBreadcrumb, BgText } from 'bangul-vue';
import CardTenantInfo from '@admin/pages/TenantTracker/components/CardTenantInfo';
import surveyTrackerProfileMixin from '@admin/pages/SurveyTracker/mixins/surveyTrackerProfileMixins';
import SurveyTableContentList from '../../components/SurveyTableContentList/SurveyTableContentList.vue';
import SurveyDetailCard from '../../components/SurveyDetailCard/SurveyDetailCard.vue';
import SurveyTrackerApi from '@/_admin/api/endpoints/survey-tracker';

export default {
  name: 'SurveyTrackerProfile',
  data() {
    return {
      profileSurveyId: '',
      trackerSurveyId: '',
      revisionId: '',
      rowNumber: '',
      isLoadData: false,
      currentPage: 1,
      limitPage: 10,
      userProfile: [],
      listTrackerSurvey: [],
      isShowCardDetail: false,
    };
  },

  components: {
    BgText,
    BgBreadcrumb,
    CardTenantInfo,
    SurveyDetailCard,
    SurveyTableContentList,
  },

  mixins: [surveyTrackerProfileMixin],

  created() {
    this.profileSurveyId = this.$route.params?.surveyId;
    this.getSurveyTrackerByProfile();
  },

  methods: {
    updateSurveyDataBasedPage(currentPage) {
      const { page } = currentPage;
      this.currentPage = page;
      this.getSurveyTrackerByProfile();
    },

    handleSortSurveyTime(sortType) {
      const params = {
        sort_by: 'survey_time',
        sort_type: sortType,
      };
      this.getSurveyTrackerByProfile(params);
    },

    handleShowCardDetail(params) {
      const { rowNumber, revisionId, surveyId } = params;
      this.revisionId = revisionId;
      this.rowNumber = rowNumber;
      this.trackerSurveyId = surveyId;
      this.isShowCardDetail = true;
    },

    handleSortUpdateSurveyTime(sortType) {
      const params = {
        sort_by: 'updated_time',
        sort_type: sortType,
      };
      this.getSurveyTrackerByProfile(params);
    },
    handleMappingUserData(surveyor) {
      this.userProfile = [
        { label: 'User ID', content: surveyor.user_id },
        { label: 'Nama Pencari Kos', content: surveyor.name },
        { label: 'No. HP', content: surveyor.phone_number },
        { label: 'Email', content: surveyor.email },
      ];
      this.breadcrumbUrl[1].name = surveyor.name;
    },

    async getSurveyTrackerByProfile(query) {
      const offsetPage = (+this.currentPage - 1) * this.limitPage;
      this.listTrackerSurvey = [];
      this.isShowCardDetail = false;
      try {
        this.isLoadData = true;
        const params = {
          ...query,
          offset: offsetPage,
          limit: this.limitPage,
        };
        const res = await SurveyTrackerApi.getSurveyTrackerByUser(
          this.profileSurveyId,
          params
        );

        this.listTrackerSurvey = res.data;
        this.handleMappingUserData(res.data.surveyor);
      } catch (error) {
        alert(error.response.data?.error);
        console.error(error.response);
      } finally {
        this.isLoadData = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./SurveyTrackerProfile.scss"></style>

import api from '@admin/api/service';

export default {
  getTrackerList(params) {
    const queryString = new URLSearchParams(params).toString();
    return api.get(`/singgahsini/api/tenant-tracker?${queryString}`);
  },
  getPhaseDetail(params) {
    const { trackerId, phase } = params;
    return api.get(`/singgahsini/api/tenant-tracker/${trackerId}/${phase}`);
  },
  getTrackerProfile(params) {
    const queryString = new URLSearchParams(params).toString();
    const { userId } = params;
    return api.get(
      `/singgahsini/api/tenant-tracker/user/${userId}?${queryString}`
    );
  },
  postTrackerNotes(params) {
    const { trackerId, status, message } = params;
    return api.post(`/singgahsini/api/tenant-tracker/${trackerId}/note`, {
      status,
      message,
    });
  },
  putTrackerNotes(params) {
    const { noteId, message } = params;
    return api.put(`/singgahsini/api/tenant-tracker/note/${noteId}`, {
      message,
    });
  },
  updateHandlingTracker(params) {
    const { trackerId, action } = params;
    return api.post(`singgahsini/api/tenant-tracker/${trackerId}/${action}`);
  },
  getTrackerDataSuggestions(params, cancelToken = null) {
    return api.post(
      '/singgahsini/api/tenant-tracker/ajax/search',
      {
        ...params,
      },
      {
        cancelToken,
      }
    );
  },
  postWhatsappTracker(params) {
    return api.post(`singgahsini/api/tenant-tracker/whatsapp`, params);
  },
  updateWhatsappTracker(params, id) {
    return api.put(`singgahsini/api/tenant-tracker/whatsapp/${id}`, params);
  },
  getWhatsappTracker(id) {
    return api.get(`singgahsini/api/tenant-tracker/${id}/chat`);
  },
  postConfirmationCheckIn(bookingId) {
    return api.post(`/singgahsini/api/booking/${bookingId}/check-in`);
  },
  postConfirmationCheckout(bookingId, params) {
    return api.post(`/singgahsini/api/booking/${bookingId}/check-out`, params);
  },
};

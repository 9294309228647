export const searchListType = [
  {
    label: 'Nama Pencari Kos',
    value: 'tenant_name',
  },
  {
    label: 'No. HP Pencari Kos',
    value: 'tenant_phone_number',
  },
  {
    label: 'Nama Properti',
    value: 'room_name',
  },
];

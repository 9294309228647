<template>
  <bg-modal
    v-model="isShowModal"
    :button-main-text="buttonMainText"
    button-second-text="Batal"
    desktop-size="lg"
    @close="closeModal"
    @click-main-action="handleClickMainButton"
    @click-second-action="closeModal"
  >
    <div class="form-update">
      <bg-text size="heading-2">{{ title }}</bg-text>
      <bg-text size="input-lg">{{ subtitle }}</bg-text>
      <div class="form-update__input">
        <bg-text size="title-3" class="form-update__input-title"
          >Nama Properti</bg-text
        >
        <bg-search-bar
          v-model="propertyName"
          :show-search-icon="false"
          :show-clear-text-icon="false"
          :suggestions="propertySuggestions"
          :disabled="!isOpenFromCreateSurvey"
          :loading="isGetPropertyData"
          :scrollable="true"
          data-testid="namaPropertiTextbox"
          placeholder="Masukkan nama properti"
          @input="getListProperty"
          @click-suggestion="handleSelectedPropertyFromSuggestion"
        />
      </div>

      <div class="form-update__input-flex">
        <div class="form-update__input">
          <bg-text size="title-3" class="form-update__input-title"
            >Nomor HP Pencari Kos</bg-text
          >
          <bg-input
            v-model="phoneNumber"
            :disabled="!isOpenFromCreateSurvey"
            data-testid="noHpTextbox"
            class="form-update__input--small"
            placeholder="Masukkan nomor HP pencari kos"
            @input="getUserByPhoneNumber"
          />

          <div v-if="phoneNumber" class="form-update__input-flex--start">
            <bg-text
              v-if="!userData.isNumberValid"
              size="label-2"
              class="form-update__input-error"
              :class="{ 'form-update__input-success': isNumberValid }"
              >{{ userData.numberStatus }}.</bg-text
            >
            <bg-link
              v-if="isNumberValid && !userData.isFillToForm"
              variant="medium"
              class="form-update__input-success form-update__link-small"
              @click="handleAutoFillFromNumberSuggestions"
              >Pakai Nomor</bg-link
            >
          </div>
        </div>
        <div class="form-update__input">
          <bg-text size="title-3" class="form-update__input-title"
            >Nama Pencari Kos</bg-text
          >
          <bg-input
            v-model="tenantName"
            :disabled="!isOpenFromCreateSurvey"
            data-testid="namaPencariKosTextbox"
            class="form-update__input--small"
            placeholder="Masukkan nama pencari kos"
          />
        </div>
      </div>

      <div class="form-update__input-flex">
        <div class="form-update__input">
          <bg-text size="title-3" class="form-update__input-title"
            >Tanggal Survei</bg-text
          >
          <bg-datepicker
            v-model="surveyDate"
            :disabled="!isEnableToUpdate"
            data-testid="surveiDateDatepicker"
            class="form-update__input--small"
            placeholder="Tanggal Survei"
          />
        </div>
        <div class="form-update__input">
          <bg-text size="title-3" class="form-update__input-title"
            >Jam Survei</bg-text
          >
          <bg-input
            v-model="surveyTime"
            data-testid="surveiTimeTextbox"
            class="form-update__input--small"
            placeholder="Masukkan jam survei"
            :disabled="!isEnableToUpdate"
            @keyup="inputTimeValidator"
          />

          <bg-text
            v-if="isShowFormatGuideTime"
            size="label-2"
            class="form-update__input-note"
            >Gunakan format sistem 24 jam (contoh: 11:00, 18:00).
          </bg-text>
        </div>
      </div>

      <div class="form-update__input-flex">
        <div class="form-update__input">
          <bg-text size="title-3" class="form-update__input-title"
            >Platform</bg-text
          >
          <bg-dropdown
            v-model="isOpenPlatform"
            :disabled="!isOpenFromCreateSurvey || isOpenFromCreateSurvey"
            trigger-size="lg"
            :trigger-text="surveyPlatform.text"
            class="form-update__input-drop-down"
          >
            <bg-dropdown-item
              v-for="(platform, i) in listPlatforms"
              :key="i"
              @click="handleUpdateSurveyPlatform(platform.label, platform.val)"
              >{{ platform.label }}</bg-dropdown-item
            >
          </bg-dropdown>
        </div>
        <div class="form-update__input">
          <bg-text size="title-3" class="form-update__input-title"
            >Status</bg-text
          >
          <bg-dropdown
            v-model="isOpenStatus"
            :disabled="!isEnableToUpdate || isOpenFromCreateSurvey"
            trigger-size="lg"
            :scrollable="true"
            :trigger-text="surveyStatus.text"
            class="form-update__input-drop-down"
            menu-placement="top-start"
          >
            <bg-dropdown-item
              v-for="(status, i) in listStatus"
              :key="i"
              @click="handleUpdateStatus(status.label, status.val)"
              >{{ status.label }}</bg-dropdown-item
            >
          </bg-dropdown>
        </div>
      </div>
      <div class="form-update__input-flex">
        <div class="form-update__input">
          <bg-text size="title-3" class="form-update__input-title"
            >User ID</bg-text
          >
          <bg-input
            disabled
            v-model="tenantId"
            placeholder="User ID"
            class="form-update__input--partial"
          />
        </div>
      </div>
    </div>
  </bg-modal>
</template>

<script>
import {
  BgModal,
  BgText,
  BgLink,
  BgInput,
  BgDatepicker,
  BgDropdown,
  BgSearchBar,
  BgDropdownItem,
} from 'bangul-vue';
import debounce from 'Utils/debounce';
import TrackerSurveyApi from '@admin_endpoints/survey-tracker';
import TrackerTenantApi from '@admin_endpoints/tenant-tracker';
import SurveyOptionsMixins from '@/_admin/pages/SurveyTracker/mixins/surveyOptionsMixins';

const INPUT_DELAY_TIME = 300;
export default {
  data() {
    return {
      isShowModal: false,
      isOpenStatus: false,
      isOpenPlatform: false,
      isGetPropertyData: false,
      isHoursInputValidated: true,
      propertyName: '',
      phoneNumber: '',
      tenantName: '',
      surveyTime: '',
      tenantId: '',
      surveyDate: new Date(),
      buttonMainText: 'Simpan',
      propertySuggestions: [],
      propertySelected: {},
      userData: { isNumberValid: true },
      surveyPlatform: { text: 'Pilih platform survei', value: '' },
      surveyStatus: { text: 'Pilih status survei', value: '' },
    };
  },

  props: {
    openModal: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: 'Ubah Detail Survei',
    },

    subtitle: {
      type: String,
      default: 'Silakan masukkan detail data survei.',
    },

    autoFillData: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    BgModal,
    BgText,
    BgLink,
    BgInput,
    BgDatepicker,
    BgDropdown,
    BgSearchBar,
    BgDropdownItem,
  },

  computed: {
    isOpenFromCreateSurvey() {
      return this.title !== 'Ubah Detail Survei';
    },

    isEnableToUpdate() {
      return this.autoFillData?.source !== 'Walk-in';
    },

    isNumberValid() {
      return this.userData.numberStatus === 'Nomor terdaftar';
    },

    isShowFormatGuideTime() {
      const maxSurveyTimeLength = 5;
      return (
        this.isOpenFromCreateSurvey &&
        this.surveyTime.length < maxSurveyTimeLength
      );
    },

    isLengthPhoneNumberValid() {
      const minValidPhoneNumber = 10;
      return this.phoneNumber.length > minValidPhoneNumber;
    },

    selectedSurveyDate() {
      let newSurveyDate = new Date(this.surveyDate);
      [newSurveyDate] = newSurveyDate.toLocaleString('sv').split(' ');

      return newSurveyDate;
    },
  },

  mixins: [SurveyOptionsMixins],

  watch: {
    isShowModal: {
      immediate: true,
      handler(isOpen) {
        if (!isOpen) {
          this.$emit('close-modal');
          this.resetFormValue();
        } else {
          this.handleFormAutoFill();
        }
      },
    },

    openModal: {
      immediate: true,
      handler(isOpen) {
        if (isOpen) this.isShowModal = this.openModal;
      },
    },

    phoneNumber: {
      immediate: true,
      handler(isChanged, isAlreadyFill) {
        if (isChanged && !!isAlreadyFill) this.resetPhoneAutoFill();
      },
    },
  },

  methods: {
    handleUpdateStatus(text, value) {
      this.surveyStatus = { text, value };
    },

    async sendUpdateDataSurvey() {
      const surveyDataUpdated = {
        status: this.surveyStatus.value,
        survey_date: this.selectedSurveyDate,
        survey_time: this.surveyTime,
      };

      try {
        this.buttonMainText = 'Loading...';
        const res = await TrackerSurveyApi.updateDataSurveyTracker(
          this.autoFillData.id,
          surveyDataUpdated
        );

        if (res.data?.tenant_survey) {
          this.$emit('on-update-success');
          this.$toast.show('Perubahan berhasil disimpan.');
        }
      } catch (error) {
        console.error(error.response.data.error);
        alert(error.response.data.error);
      } finally {
        this.closeModal();
      }
    },

    closeModal() {
      this.buttonMainText = 'Simpan';
      this.$emit('close-modal');
      this.isShowModal = false;
    },

    handleUpdateSurveyPlatform(platformText, value) {
      this.surveyPlatform = { text: platformText, value };
    },

    setDefaultValueOnNewSurvey() {
      this.surveyPlatform = { text: 'Walk-in', value: 'walk_in' };
      this.surveyStatus = { text: 'Datang Survei', value: 'presented' };
      this.buttonMainText = 'Tambahkan';
    },

    getSuggestionData(params) {
      return TrackerTenantApi.getTrackerDataSuggestions(params);
    },

    handleSelectedPropertyFromSuggestion(property) {
      this.propertySelected = property;
      this.propertyName = property.label;
      this.propertySuggestions = [];
    },

    handleAutoFillFromNumberSuggestions() {
      this.userData = {
        ...this.userData,
        isNumberValid: true,
        isFillToForm: true,
      };

      this.tenantName = this.userData.value;
      this.tenantId = this.userData.id;
    },

    handleClickMainButton() {
      if (this.isOpenFromCreateSurvey) {
        this.submitNewSurvey();
      } else {
        this.sendUpdateDataSurvey();
      }
    },

    resetPhoneAutoFill() {
      this.tenantName = '';
      this.tenantId = '';
    },

    resetFormValue() {
      this.propertyName = '';
      this.userData = '';
      this.phoneNumber = '';
      this.tenantName = '';
      this.tenantId = '';
      this.surveyTime = '';
      this.propertySelected = {};
      this.userData = { isNumberValid: true };
    },

    inputTimeValidator: debounce(function () {
      if (this.surveyTime?.length < 1) {
        return true;
      }
      if (this.surveyTime.indexOf(':') >= 0) {
        this.surveyTime = this.surveyTime.split(':').join('');
      }
      const maxHours = 24;
      const maxMinutes = 60;
      const inputHours =
        +this.surveyTime.slice(0, 2) >= maxHours
          ? 23
          : this.surveyTime.slice(0, 2);
      const inputMinutes =
        +this.surveyTime.slice(2, 4) >= maxMinutes
          ? 59
          : this.surveyTime.slice(2, 4);
      const modifiedValue =
        this.surveyTime.length > 2
          ? `${inputHours}:${inputMinutes}`
          : this.surveyTime;

      this.surveyTime = modifiedValue;
    }, INPUT_DELAY_TIME),

    handleFormAutoFill() {
      if (!this.autoFillData && this.isOpenFromCreateSurvey) {
        this.setDefaultValueOnNewSurvey();
      } else {
        const { room, surveyor } = this.autoFillData;
        const maxOneDigitTime = 9;
        const indexOfStatus = this.listStatus.findIndex(
          surveyStatus => surveyStatus.label === this.autoFillData.status
        );
        const indexOfPlatform = this.listPlatforms.findIndex(
          platform => platform.label === this.autoFillData.source
        );

        this.propertyName = room.name;
        this.phoneNumber = surveyor.phone_number;
        this.tenantName = surveyor.name;
        this.tenantId = surveyor.user_id;
        this.surveyPlatform = {
          text: this.listPlatforms[indexOfPlatform].label,
          value: this.listPlatforms[indexOfPlatform].val,
        };
        this.surveyStatus = {
          text: this.listStatus[indexOfStatus].label,
          value: this.listStatus[indexOfStatus].val,
        };

        // Validation  Format Time
        this.surveyDate = new Date(this.autoFillData.survey_datetime);
        let hours = this.surveyDate.getHours();
        let minutes = this.surveyDate.getMinutes();
        hours = hours <= maxOneDigitTime ? `0${hours}` : `${hours}`;
        minutes = minutes <= maxOneDigitTime ? `0${minutes}` : `${minutes}`;
        this.surveyTime = `${hours}:${minutes}`;
      }
    },

    getListProperty: debounce(async function () {
      try {
        this.isGetPropertyData = true;
        const res = await this.getSuggestionData({
          type: 'room',
          value: this.propertyName,
        });
        this.propertySuggestions = res?.data?.suggestions?.map(property => ({
          label: property.value,
          id: property.id,
        }));
      } catch (error) {
        console.error(error);
        alert(error.response?.data?.message);
      } finally {
        this.isGetPropertyData = false;
      }
    }, INPUT_DELAY_TIME),

    getUserByPhoneNumber: debounce(async function () {
      if (!this.isLengthPhoneNumberValid) {
        this.userData = {
          isNumberValid: false,
          numberStatus: 'Nomor tidak terdaftar',
        };
        return;
      }
      try {
        this.userData = { numberStatus: 'Checking...' };
        this.isGetPropertyData = true;
        const res = await this.getSuggestionData({
          type: 'phone_number',
          value: this.phoneNumber,
        });

        if (res.data.suggestions?.length > 0) {
          this.userData = {
            ...res.data.suggestions[0],
            numberStatus: 'Nomor terdaftar',
          };
        } else {
          this.userData = {
            isNumberValid: false,
            numberStatus: 'Nomor tidak terdaftar',
          };
        }
      } catch (error) {
        console.error(error);
        alert(error.response?.data?.error);
      } finally {
        this.isGetPropertyData = false;
      }
    }, INPUT_DELAY_TIME),

    async submitNewSurvey() {
      this.buttonMainText = 'Loading...';
      const newSurveyData = {
        room_id: this.propertySelected.id,
        surveyor: {
          user_id: this.tenantId,
          name: this.tenantName,
          phone_number: this.phoneNumber,
        },
        survey_date: this.selectedSurveyDate,
        survey_time: this.surveyTime,
      };

      try {
        const res = await TrackerSurveyApi.postSurveyTracker(newSurveyData);

        if (res.data?.tenant_survey) {
          this.$emit('on-survey-created');
          this.$toast.show('Data survei berhasil ditambah.');
          this.closeModal();
        }
      } catch (error) {
        this.buttonMainText = 'Tambahkan';
        console.error(error.response.data);
        alert(error.response?.data?.error);
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./SurveyFormUpdateModal.scss"></style>

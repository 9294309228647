<template>
  <div class="survey-table-content-list">
    <table
      class="ss-table survey-table-content-list__table"
      data-testId="tenant-table-list"
    >
      <thead>
        <tr>
          <th
            v-for="(header, i) in tableHeaders"
            :aria-label="header.text"
            :key="i"
          >
            <div
              class="table-header"
              :class="{
                'table-header__align-left': isHeaderForDate(header.text).value,
              }"
              @click="
                updateSortingList(isHeaderForDate(header.text), header.text)
              "
            >
              <bg-text
                class="table-header__text"
                :class="{
                  'table-header__date': isHeaderForDate(header.text).value,
                }"
                size="title-5"
                >{{ header.text }}</bg-text
              >
              <span
                v-if="isHeaderForDate(header.text)"
                class="table-header__icon"
              >
                <bg-icon
                  v-if="isHeaderForDate(header.text).isSurveyTime"
                  :name="surveyTimeIconSortType"
                  size="sm"
                />
                <bg-icon
                  v-if="isHeaderForDate(header.text).isUpdateSurveyTime"
                  :name="surveyUpdateIconSortType"
                  size="sm"
                />
              </span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="isLoadData">
          <tr v-for="i in 5" :key="i">
            <td v-for="j in tableHeaders.length" :key="j">
              <bg-skeleton width="100%" :height="24" />
            </td>
          </tr>
        </template>
        <template v-else-if="!isEmptyList">
          <tr
            v-for="(list, i) in tableContent.surveys"
            :key="i"
            :class="{
              'table-body__row-red': checkingRowNeedAction(
                list.timeline_status,
                list.status
              ),
              'table-body__row-purple': isEnableHoverEffect,
              'table-body__row-purple--active':
                isEnableHoverEffect && rowActive === i,
            }"
            @click="
              handleClickRow({
                rowNumber: i,
                surveyId: list.id,
                revisionId: list.revision_id,
              })
            "
          >
            <td>
              <bg-text size="input-lg"> {{ list.id }} </bg-text>
            </td>
            <td v-if="list.surveyor">
              <bg-text size="input-lg">
                <bg-link
                  tag="router-link"
                  :to="{
                    name: 'survey-tracker.profile',
                    params: { surveyId: list.id },
                  }"
                >
                  {{ list.surveyor.name }}
                </bg-link>
                <br />
                {{ list.surveyor.phone_number }}
              </bg-text>
            </td>
            <td>
              <bg-link
                :href="getPropertyDetailOverviewPath(list.room.property_id)"
                target="_blank"
                rel="noopener noreferrer"
                @click="showInvalidPropertyIdAlert(list.room.property_id)"
              >
                {{ list.room.name }}
              </bg-link>
            </td>
            <td>
              <div class="table-body__date">
                {{
                  mixDateFormatterToDisplay(list.survey_datetime, 'DD/MM/YYYY')
                }}
                <br />
                <div class="table-body__date--float-right">
                  {{ mixDateFormatterToDisplay(list.survey_datetime, 'HH:mm') }}
                </div>
              </div>
            </td>
            <td>
              <bg-label-rainbow
                :color="generateLabelType(list.phase)"
                class="table-body__capitalize"
              >
                {{ list.source }}
              </bg-label-rainbow>
            </td>
            <td>
              <bg-label-rainbow
                :color="generateLabelType(list.status)"
                class="table-body__label"
              >
                {{ list.status }}
              </bg-label-rainbow>
              <bg-label-rainbow
                v-if="list.timeline_status === 'ongoing'"
                color="yellow"
                class="table-body__label"
              >
                Hari H Survei
              </bg-label-rainbow>
            </td>

            <td>
              <div class="table-body__date">
                {{ mixDateFormatterToDisplay(list.updated_time, 'DD/MM/YYYY') }}
                <br />
                <div class="table-body__date--float-right">
                  {{ mixDateFormatterToDisplay(list.updated_time, 'HH:mm') }}
                </div>
              </div>
            </td>
            <td>{{ list.updated_by }}</td>
            <td v-if="isShowActionHeader">
              <bg-icon
                name="more-vertical"
                class="table-body__action-button"
                title="open options for this item"
                data-testid="row-action-icon"
                @click.native="handleClickAction({ listId: list.id })"
              />
              <bg-card
                v-if="
                  isShowCardAction.active &&
                  isShowCardAction.actionId === list.id
                "
                class="table-body__action"
              >
                <bg-list-item
                  v-for="(action, i) in actions"
                  :key="i"
                  data-testid="row-action-button"
                >
                  <div
                    class="table-body__action-row"
                    @click="action.actionClick(list)"
                  >
                    <bg-icon
                      :name="action.icon"
                      size="md"
                      class="table-body__action-icon"
                    />
                    <bg-text v-if="action.title === 'follow-up'" size="input-lg"
                      >{{ generateFollowUpStatus(list.handled) }}
                    </bg-text>
                    <bg-text v-else size="input-lg">{{ action.title }}</bg-text>
                  </div>
                </bg-list-item>
              </bg-card>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <bg-pagination
      v-if="!isLoadData && !isEmptyList"
      v-model="currentPage"
      :page-total="totalPage"
      @click="handleChangePage"
    />
    <survey-form-update-modal
      :open-modal="isOpenModal"
      :auto-fill-data="rowSelectedData"
      @close-modal="closeModalUpdateSurvey"
      @on-update-success="handleChangePage"
    />
  </div>
</template>

<script>
import {
  BgText,
  BgIcon,
  BgLink,
  BgCard,
  BgListItem,
  BgPagination,
  BgLabelRainbow,
  BgSkeleton,
} from 'bangul-vue';
import labelUtils from '@admin/mixins/labelGenerator';
import mixDateFormatterToDisplay from '@admin/mixins/formatter';
import { headers } from '../../Utils/tableProperty';
import SurveyFormUpdateModal from '../SurveyFormUpdateModal';

export default {
  name: 'SurveyTableListContent',

  data() {
    return {
      headers,
      rowActive: '',
      totalPage: 0,
      limitPage: 10,
      currentPage: 1,
      diffPagination: 1,
      isOpenModal: false,
      timeSurveySortType: 'desc',
      updateSurveySortType: 'desc',
      rowSelectedData: {},
      isShowCardAction: {
        active: false,
        actionId: 0,
      },
      searchBoxProperty: ['tenant_name', 'room_name', 'tenant_phone_number'],
      storageParams: {},
    };
  },

  props: {
    tableContent: {
      type: [Object, Array],
      require: true,
    },

    isLoadData: {
      type: Boolean,
      default: false,
    },

    customHeaders: {
      type: Array,
      default: () => [],
    },

    isEnableHoverEffect: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [labelUtils, mixDateFormatterToDisplay],

  components: {
    BgText,
    BgIcon,
    BgLink,
    BgCard,
    BgListItem,
    BgPagination,
    BgLabelRainbow,
    BgSkeleton,
    SurveyFormUpdateModal,
  },

  created() {
    window.addEventListener('click', () => this.handleClickAction(false), {
      capture: true,
    });
    this.storageParams = JSON.parse(
      sessionStorage.getItem('surveySearchFilter')
    );
    this.totalPage = this.tableContent?.pagination?.total_page || 0;
    this.currentPage = (this.tableContent?.pagination?.offset || 0) / 10;
  },

  beforeDestroy() {
    window.removeEventListener('click', () => this.handleClickAction(false), {
      capture: true,
    });
  },

  computed: {
    surveyTimeIconSortType() {
      return this.timeSurveySortType === 'desc' ? 'down' : 'up';
    },

    surveyUpdateIconSortType() {
      return this.updateSurveySortType === 'desc' ? 'down' : 'up';
    },
    isEmptyList() {
      return this.tableContent.surveys?.length === 0;
    },

    tableHeaders() {
      const isUseCustomHeader = this.customHeaders?.length > 0;

      return isUseCustomHeader ? this.customHeaders : this.headers;
    },

    isShowActionHeader() {
      return !this.tableHeaders
        .map(header => header.text === 'Action')
        .every(result => !result);
    },

    actions() {
      return [
        {
          title: 'Edit',
          actionClick: rowData => {
            this.updateDataSurvey(rowData);
          },
          icon: 'edit',
        },
      ];
    },
  },

  watch: {
    isLoadData: {
      immediate: true,
      handler(isLoadData) {
        if (this.tableContent?.pagination && !isLoadData) {
          this.totalPage =
            Math.ceil(this.tableContent.pagination.total / this.limitPage) || 0;
          this.currentPage =
            this.tableContent.pagination.offset / this.limitPage;
          this.currentPage += this.diffPagination;
        }
      },
    },
  },

  methods: {
    isHeaderForDate(headerContent) {
      const value =
        headerContent === 'Waktu Survei' || headerContent === 'Update Terakhir';
      const isSurveyTime = headerContent === 'Waktu Survei';
      const isUpdateSurveyTime = headerContent === 'Update Terakhir';
      return { value, isSurveyTime, isUpdateSurveyTime };
    },

    updateSortingList(isUpdateSortType, sortType) {
      /* istanbul ignore else */
      if (sortType === 'Waktu Survei') {
        this.timeSurveySortType =
          this.timeSurveySortType === 'desc' ? 'asc' : 'desc';
        this.$emit('on-click-sort-survey-time', this.timeSurveySortType);
      } else if (sortType === 'Update Terakhir') {
        this.updateSurveySortType =
          this.updateSurveySortType === 'desc' ? 'asc' : 'desc';
        this.$emit('on-click-sort-update-survey', this.updateSurveySortType);
      }

      return isUpdateSortType;
    },

    getPropertyDetailOverviewPath(propertyId) {
      if (propertyId) {
        return `/property-detail/${propertyId}/overview`;
      }

      return null;
    },

    showInvalidPropertyIdAlert(propertyId) {
      if (!propertyId) {
        alert('Data property tidak valid');
      }
    },

    handleClickAction({ isSetActive = true, listId }) {
      this.isShowCardAction.active = isSetActive;
      this.isShowCardAction.actionId = listId;
    },

    handleChangePage() {
      this.storageParams = JSON.parse(
        sessionStorage.getItem('surveySearchFilter')
      );
      const changePageParams = {
        query: this.storageParams,
        page: this.currentPage,
      };

      this.$emit('on-click-page', changePageParams);
    },

    handleClickRow(rowData) {
      const { rowNumber } = rowData;
      this.rowActive = rowNumber;
      this.$emit('on-row-clicked', rowData);
    },

    closeModalUpdateSurvey() {
      this.rowSelectedData = {};
      this.isOpenModal = false;
    },

    updateDataSurvey(surveyData) {
      this.rowSelectedData = surveyData;
      this.isOpenModal = true;
    },

    checkingRowNeedAction(schedule, status) {
      return schedule === 'past' && status === 'Diajukan';
    },
  },
};
</script>

<style lang="scss" scoped src="./SurveyTableContentList.scss"></style>

<template>
  <div class="survey-phase">
    <div class="survey-phase__container">
      <div class="survey-phase__wrapper">
        <bg-text size="title-5">Tanggal Survei</bg-text>
        <bg-text size="body-2">{{
          mixDateFormatterToDisplay(contentSurveyPhase.survey_date)
        }}</bg-text>
      </div>
      <div class="survey-phase__wrapper">
        <bg-text size="title-5">Waktu Survei</bg-text>
        <bg-text size="body-2">{{
          mixDateFormatterToDisplay(contentSurveyPhase.survey_date, 'HH:mm')
        }}</bg-text>
      </div>
    </div>
    <div class="survey-phase__container">
      <div class="survey-phase__number">
        <bg-text size="title-5">No. WhatsApp Tenant</bg-text>
        <bg-text size="body-2">{{ tenantNumber }}</bg-text>
      </div>
    </div>
    <bg-divider dashed class="survey-phase__divider" />
    <div class="survey-phase__container">
      <div class="survey-phase__wrapper">
        <bg-text size="title-5">Nama Penjaga</bg-text>
        <bg-text size="body-2">{{ roomKeeperName }}</bg-text>
      </div>
      <div class="survey-phase__wrapper">
        <bg-text size="title-5">Kontak Penjaga</bg-text>
        <bg-link
          :variant="roomKeeperNumber ? 'medium' : 'medium-naked'"
          data-testid="button-chat-room-keeper"
          @click="handleCallRoomGuard"
          >{{ roomKeeperNumber ? 'Chat penjaga' : '-' }}</bg-link
        >
      </div>
    </div>
    <bg-divider class="survey-phase__divider" />
    <div
      v-if="isEnableConfirmSurvey"
      class="survey-phase__container survey-phase__footer"
    >
      <bg-button size="sm" class="survey-phase__button">Tolak</bg-button>
      <bg-button
        variant="secondary"
        size="sm"
        active
        width="134"
        class="survey-phase__button"
        >Konfirmasi Survey</bg-button
      >
    </div>
  </div>
</template>

<script>
import { BgText, BgDivider, BgLink, BgButton } from 'bangul-vue';
import mixDateFormatterToDisplay from '@admin/mixins/formatter';

export default {
  name: 'TenantSurveyPhase',

  data() {
    return {
      isEnableConfirmSurvey: false,
    };
  },

  components: {
    BgText,
    BgLink,
    BgButton,
    BgDivider,
  },
  mixins: [mixDateFormatterToDisplay],
  props: {
    contentSurveyPhase: {
      type: Object,
      require: true,
      default: /* istanbul ignore next */ () => {},
    },
  },

  computed: {
    roomKeeperName() {
      return this.contentSurveyPhase?.room_keeper_name || '-';
    },
    roomKeeperNumber() {
      return this.contentSurveyPhase.room_keeper_phone_number;
    },

    tenantNumber() {
      return this.contentSurveyPhase?.user_phone_number || '-';
    },
  },

  methods: {
    handleCallRoomGuard() {
      /* istanbul ignore else */
      if (this.roomKeeperNumber) {
        window.open(`https://wa.me/${this.roomKeeperNumber}`);
        window.opener = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./TenantSurveyPhase.scss"></style>

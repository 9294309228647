import dayjs from 'dayjs';
import 'dayjs/locale/id';

dayjs.locale('id');

export default {
  methods: {
    // Convert
    // Date Wed Jun 30 2021 11:54:01 GMT+0700 (Western Indonesia Time)
    // Into
    // 2021-06-30
    mixDateFormatterToSend(value) {
      const formatted = dayjs(value).format('YYYY-MM-DD');
      return formatted;
    },

    // Convert
    // 2021-01-01 13:29:10
    // Into
    // 01 Jan 2021
    mixDateFormatterToDisplay(value, format = 'DD MMM YYYY') {
      const formatted = dayjs(value).format(format);

      return formatted;
    },

    // Convert
    // 2021-01-01 13:29:10
    // Into
    // Januari 2021
    mixDateFormatterToDisplayMonthYear(value, format = 'MMMM YYYY') {
      const formatted = dayjs(value).format(format);

      return formatted;
    },

    // Convert
    // 10000
    // Into
    // Rp10.000
    mixNumberFormatterToRupiah(value) {
      return `Rp${value
        .toString()
        .replace(/(?!\.)\D/g, '')
        .replace(/(?:\..*)\./g, '')
        .replace(/(?:\.\d\d).*/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
    },

    // Convert
    // Object to URL params
    mixObjectFormatterToURLParams(obj) {
      const str = Object.keys(obj)
        .map(k => {
          if (obj[k] instanceof Array) {
            let string = '';
            if (obj[k].length) {
              obj[k].forEach(item => {
                string += `${encodeURIComponent(k)}[]=${encodeURIComponent(
                  item
                )}&`;
              });
            } else {
              string += `${encodeURIComponent(k)}[]=&`;
            }
            string = string.slice(0, -1);
            return string;
          }
          return `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`;
        })
        .join('&');
      return str;
    },
  },
};

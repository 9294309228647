<template>
  <div class="survey__detail-card fadeInRight">
    <bg-card>
      <div v-if="!isLoadData && surveyDetail" class="survey__scroll-view">
        <div class="survey__label-wrapper">
          <bg-text size="title-4">Survei</bg-text>
        </div>
        <bg-label-rainbow
          :color="generateLabelType(surveyDetail.status)"
          class="survey__label"
          >{{ surveyDetail.status }}</bg-label-rainbow
        >
        <div class="survey__room">
          <div class="survey__room-wrapper">
            <bg-text size="body-1" class="survey__room-title">{{
              surveyDetail.room.name
            }}</bg-text>
            <bg-image
              v-if="surveyDetail.room.images"
              :src="surveyDetail.room.images.small"
              ratio="4:1"
              class="survey__room-image"
            />
          </div>
          <bg-text size="body-2" class="survey__room-subtitle"
            >{{ propertyInfo }}
          </bg-text>
          <div class="survey__link">
            <bg-link @click="handleOpenMaps" variant="medium"
              >{{ surveyDetail.room.address }}
            </bg-link>
          </div>
        </div>
        <bg-divider dashed class="survey__divider" />
        <tenant-survey-phase :content-survey-phase="contentSurveyPhase" />
      </div>
      <skeleton-loader v-else :columns="2" />
    </bg-card>
  </div>
</template>

<script>
import {
  BgCard,
  BgDivider,
  BgText,
  BgLink,
  BgImage,
  BgLabelRainbow,
} from 'bangul-vue';
import labelUtils from '@admin/mixins/labelGenerator';
import TenantSurveyPhase from '@/_admin/pages/TenantTracker/components/TenantSurveyPhase/TenantSurveyPhase.vue';
import SkeletonLoader from '@/_admin/pages/TenantTracker/components/SkeletonLoader';
import SurveyTrackerAPI from '@/_admin/api/endpoints/survey-tracker';

export default {
  name: 'SurveyDetailCard',

  data() {
    return {
      isLoadData: false,
      surveyDetail: {},
      contentSurveyPhase: {},
    };
  },

  components: {
    BgCard,
    BgDivider,
    BgText,
    BgImage,
    BgLink,
    SkeletonLoader,
    BgLabelRainbow,
    TenantSurveyPhase,
  },

  computed: {
    propertyInfo() {
      return `Tipe ${this.surveyDetail.room?.unit_type || '-'}・${
        this.surveyDetail.room?.gender || '#'
      }・Sisa ${this.surveyDetail.room.total_room_available} kamar`;
    },
  },

  props: {
    surveyId: {
      type: String,
      default: '',
    },

    revisionId: {
      type: String,
      default: '',
    },

    rowNumber: {
      type: String,
      default: '',
    },
  },

  mixins: [labelUtils],

  watch: {
    rowNumber: {
      immediate: true,
      handler(isRowClicked) {
        if (isRowClicked) this.getSurveyTrackerDetail();
      },
    },
  },

  methods: {
    handleOpenMaps() {
      const latitude = this.surveyDetail.room.location?.lat;
      const longitude = this.surveyDetail.room.location?.long;

      if (latitude && longitude) {
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
        );
        window.opener = null;
      }
    },

    handleMappingValueSurvey() {
      const { room, surveyor, survey_datetime } = this.surveyDetail;

      this.contentSurveyPhase = {
        room_keeper_name: room.keeper_name,
        room_keeper_phone_number: room.keeper_phone_number,
        user_phone_number: surveyor.phone_number,
        survey_date: survey_datetime,
      };
    },

    async getSurveyTrackerDetail() {
      if (!this.revisionId && !this.surveyId) return;
      try {
        this.isLoadData = true;
        const res = await SurveyTrackerAPI.getSurveyTrackerDetail({
          surveyId: this.surveyId,
          revisionId: this.revisionId,
        });
        if (res.data?.survey) {
          this.surveyDetail = res.data.survey;
          this.handleMappingValueSurvey();
        } else {
          alert('Terjadi kesalahan, silahkan coba lagi');
        }
      } catch (error) {
        console.error(error.response);
      } finally {
        this.isLoadData = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./SurveyDetailCard.scss"></style>

<template>
  <div class="survey-action">
    <bg-dropdown
      class="survey-action__search-type"
      :close-on-select="true"
      :triggerText="searchLabel"
      trigger-size="lg"
    >
      <bg-dropdown-item
        v-for="(search, key) in searchListType"
        :key="key"
        :active="searchType === search.value"
        @click="updateSearchType(search.value)"
        >{{ search.label }}</bg-dropdown-item
      >
    </bg-dropdown>

    <div class="survey-action__search">
      <bg-search-bar
        v-model="searchValue"
        :placeholder="searchPlaceholder"
        @keyup.enter="handleSearchFilter"
        @input.native="handleNumber"
        size="lg"
      />
    </div>
    <bg-button
      icon="filter"
      size="md"
      block
      class="survey-action__button"
      @click="handleClickFilter('open')"
    >
      <bg-badge-counter
        v-if="filterModalCount > 0"
        variant="black"
        :value="filterModalCount"
      />
      <bg-icon v-else name="filter" />
      <span class="survey-action__filter">Filter</span>
    </bg-button>

    <div class="survey-action__button">
      <bg-button variant="primary" size="lg" block @click="handleSearchFilter"
        >Cari</bg-button
      >
    </div>
    <div class="survey-action__button">
      <bg-button
        variant="primary"
        size="lg"
        @click="resetSearchHistory"
        :disabled="isResetDisabled"
        block
        >Reset</bg-button
      >
    </div>
    <survey-filter-action-modal
      ref="survey_filter_action_modal"
      :openModal="isShowModalFilter"
      @close-modal="handleClickFilter('close')"
      @handle-reset-button="isResetDisabled = false"
      @handle-filter-count="countFilterModal"
    />
  </div>
</template>

<script>
import {
  BgDropdown,
  BgDropdownItem,
  BgSearchBar,
  BgButton,
  BgIcon,
  BgBadgeCounter,
} from 'bangul-vue';

import { dateFormatterToSend } from 'Utils/formatter';
import { searchListType } from '@/_admin/pages/SurveyTracker/Utils/filterProperty';
import SurveyFilterActionModal from '../SurveyFilterActionModal';

export default {
  name: 'SurveyTopActionBar',

  data() {
    return {
      searchListType,
      searchValue: '',
      filterModalCount: 0,
      isResetDisabled: true,
      isShowModalFilter: false,
      searchType: 'tenant_name',
      storageParams: {},
      searchBoxProperty: ['kost_name', 'tenant_name', 'tenant_phone_number'],
      objectProperties: [
        'tenant_name',
        'tenant_phone_number',
        'room_name',
        'updated_date[start]',
        'updated_date[end]',
        'survey_date[start]',
        'survey_date[end]',
        'status',
        'source'
      ],
    };
  },

  components: {
    BgDropdown,
    BgDropdownItem,
    BgSearchBar,
    BgIcon,
    BgButton,
    BgBadgeCounter,
    SurveyFilterActionModal,
  },

  created() {
    this.sessionStorage = JSON.parse(sessionStorage.getItem('surveySearchFilter'));

    this.objectProperties.forEach(property => {
      if (this.sessionStorage) {
        if (this.sessionStorage[property]) {
          if (this.searchBoxProperty.includes(property)) {
            this.searchValue = this.sessionStorage[property];
            this.isResetDisabled = false;
          }
        }
      }
    });
  },

  computed: {
    searchPlaceholder() {
      if (this.searchType === 'tenant_name') {
        return `Cari berdasarkan ${this.searchLabel} / User ID`;
      }
      return `Cari berdasarkan ${this.searchLabel}`;
    },
    searchLabel() {
      const usedSearchType = this.defaultSearchType
        ? this.defaultSearchType
        : this.searchType;
      return this.searchListType.find(type => type.value === usedSearchType)
        .label;
    },
  },

  methods: {
    countFilterModal(count) {
      this.filterModalCount = count;
      if (count > 0) {
        this.isResetDisabled = false;
      }
    },

    handleSearchFilter() {
      this.isResetDisabled = false;
      this.searchParam = {
        [this.searchType]: this.searchValue,
        offset: 0
      }
      this.actionSearch();
    },

    actionSearch() {
      this.storageParams = JSON.parse(sessionStorage.getItem('surveySearchFilter'));
      if (this.storageParams) {
        if (this.storageParams[`updated_date[start]`]) {
          const updateStartDate = dateFormatterToSend(
            this.storageParams[`updated_date[start]`]
          );
          this.storageParams[`updated_date[start]`] = updateStartDate;
        }
        if (this.storageParams[`updated_date[end]`]) {
          const updateEndDate = dateFormatterToSend(
            this.storageParams[`updated_date[end]`]
          );
          this.storageParams[`updated_date[end]`] = updateEndDate;
        }
        if (this.storageParams[`survey_date[start]`]) {
          const surveyStartDate = dateFormatterToSend(
            this.storageParams[`survey_date[start]`]
          );
          this.storageParams[`survey_date[start]`] = surveyStartDate;
        }
        if (this.storageParams[`survey_date[end]`]) {
          const surveyEndDate = dateFormatterToSend(
            this.storageParams[`survey_date[end]`]
          );
          this.storageParams[`survey_date[end]`] = surveyEndDate;
        }
      }

      const filterActionParameters = {
        ...this.storageParams,
        ...this.searchParam,
      };

      sessionStorage.setItem('surveySearchFilter', JSON.stringify(filterActionParameters));
      this.$emit('on-search-filter', filterActionParameters);
    },

    handleNumber() {
      if (this.searchType === 'tenant_phone_number') {
        this.searchValue = this.searchValue.replace(/[^0-9+]/g, '');
      }
    },

    updateSearchType(newValue) {
      this.resetSearchHistory();
      this.searchType = newValue;
    },

    resetSearchHistory() {
      this.clearLastSearchValue();
      if (this.filterModalCount > 0) {
        this.$refs.survey_filter_action_modal.handleResetParameters();
      }
      this.filterModalCount = 0;
      sessionStorage.setItem('surveySearchFilter', null);
      this.$emit('on-search-filter', null);
    },

    clearLastSearchValue() {
      this.searchValue = '';
      this.isResetDisabled = true;
      this.searchParam = {
        tenant_name: '',
        tenant_phone_number: '',
        room_name: '',
      };
    },

    handleClickFilter(action) {
      this.isShowModalFilter = action === 'open';
    },
  },
};
</script>
<style lang="scss" scoped src="./SurveyTopActionBar.scss"></style>

<template>
  <bg-card class="card-profile">
    <bg-text size="title-3" class="card-profile__table-title">
      Profil Penyewa
    </bg-text>
    <bg-divider />
    <div class="card-profile__data">
      <div
        v-for="({ label, content }, index) in profileData"
        :key="index"
        class="card-profile__data-wrapper"
      >
        <bg-text size="button-lg" class="card-profile__data-subtitle">
          {{ label }}
        </bg-text>
        <bg-link
          v-if="label === 'No. HP'"
          variant="medium"
          target="_blank"
          rel="noopener noreferrer"
          :href="getWhatsappLink(content)"
        >
          {{ content }}
        </bg-link>
        <bg-text v-else size="input-lg" class="card-profile__data-content">
          {{ content }}
        </bg-text>
      </div>
    </div>
  </bg-card>
</template>

<script>
import { BgText, BgCard, BgDivider, BgLink } from 'bangul-vue';
import { getWhatsappLink } from 'Utils/whatsapp';

export default {
  name: 'CardTenantInfo',

  components: {
    BgText,
    BgCard,
    BgDivider,
    BgLink,
  },

  props: {
    profileData: {
      type: [Array, Object],
      required: true,
    },
  },

  methods: {
    getWhatsappLink,
  },
};
</script>

<style lang="scss" scoped src="./CardTenantInfo.scss"></style>

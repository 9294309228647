export default {
  computed: {
    listPlatforms() {
      return [
      {
        label: 'Form Aplikasi',
        val: 'apps_form'
      },
      {
        label: 'Walk-in',
        val: 'walk_in'
      }
    ]},

    listStatus() {
      return [
      {
        label: 'Diajukan',
        val: 'submitted'
      },
      {
        label: 'Dibatalkan',
        val: 'cancelled'
      },
      {
        label: 'Datang Survei',
        val: 'presented'
      },
      {
        label: 'Tidak Datang Survei',
        val: 'not_presented'
      },
      {
        label: 'Tidak Ada Konfirmasi',
        val: 'no_confirmation'
      }
    ]},
  },
};

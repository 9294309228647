<template>
  <bg-modal
    v-model="isShowModal"
    button-main-text="Terapkan"
    button-second-text="Reset"
    desktop-size="lg"
    class="filter-form"
    @close="isShowModal = false"
    @click-main-action="actionSearch"
    @click-second-action="handleResetSearch"
  >
    <bg-text size="heading-4">Filter Survei</bg-text>
    <bg-text size="body-2">Silakan pilih sesuai kebutuhan Anda</bg-text>
    <bg-text size="title-3" class="filter-form__title-section"
      >Rentang Waktu Survei</bg-text
    >
    <bg-grid>
      <bg-grid-item>
        <bg-text size="body-2" class="filter-form__input-title"
          >Tanggal Mulai</bg-text
        >
        <bg-datepicker
          v-model="advancedSearchParams[`survey_date[start]`]"
          data-testid="surveyTimeStartDate"
          placeholder="Pilih tanggal di sini"
        />
      </bg-grid-item>
      <bg-grid-item>
        <bg-text size="body-2" class="filter-form__input-title"
          >Tanggal Akhir</bg-text
        >
        <bg-datepicker
          v-model="advancedSearchParams[`survey_date[end]`]"
          placeholder="Pilih tanggal di sini"
          data-testid="surveyTimeEndDate"
          :disabled-dates="disabledSurveyDate"
      /></bg-grid-item>
    </bg-grid>
    <bg-text size="title-3">Rentang Tanggal Update</bg-text>
    <bg-grid>
      <bg-grid-item>
        <bg-text size="body-2" class="filter-form__input-title"
          >Tanggal Mulai</bg-text
        >
        <bg-datepicker
          v-model="advancedSearchParams[`updated_date[start]`]"
          data-testid="updateTimeStartDate"
          placeholder="Pilih tanggal di sini"
      /></bg-grid-item>
      <bg-grid-item>
        <bg-text size="body-2" class="filter-form__input-title"
          >Tanggal Akhir</bg-text
        >
        <bg-datepicker
          v-model="advancedSearchParams[`updated_date[end]`]"
          data-testid="updateTimeEndDate"
          placeholder="Pilih tanggal di sini"
          :disabled-dates="disabledUpdatedDate"
      /></bg-grid-item>
    </bg-grid>
    <bg-grid>
      <bg-grid-item>
        <bg-text size="title-3" class="filter-form__input-title"
          >Platform</bg-text
        >
        <bg-select
          v-model="advancedSearchParams.source"
          :options="listPlatforms"
          data-testid="platformDropdown"
          placeholder="Pilih platform survei"
          menu-placement="top-start"
          class="filter-form__drop-down"
        />
      </bg-grid-item>
      <bg-grid-item
        ><bg-text size="title-3" class="filter-form__input-title"
          >Status</bg-text
        >
        <bg-select
          v-model="advancedSearchParams.status"
          :options="listStatus"
          placeholder="Pilih status survei"
          menu-placement="top-start"
          class="filter-form__drop-down"
        />
      </bg-grid-item>
    </bg-grid>
  </bg-modal>
</template>
<script>
import {
  BgModal,
  BgText,
  BgGrid,
  BgGridItem,
  BgDatepicker,
  BgSelect,
} from 'bangul-vue';
import SurveyOptionsMixins from '@/_admin/pages/SurveyTracker/mixins/surveyOptionsMixins';

export default {
  name: 'SurveyFilterActionModal',
  data() {
    return {
      isShowModal: false,
      surveyDate: new Date(),
      isOpenPlatform: false,
      isOpenStatus: false,
      advancedSearchParams: {},
      countFilter: 0,
      exemptProperty: [
        'sort_by',
        'limit',
        'sort_type',
        'tenant_name',
        'room_name',
        'tenant_phone_number',
        'offset',
      ],
      filterProperties: [
        'survey_date[start]',
        'survey_date[end]',
        'updated_date[start]',
        'updated_date[end]',
        'status',
        'source',
      ],
    };
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [SurveyOptionsMixins],
  components: {
    BgModal,
    BgText,
    BgGrid,
    BgGridItem,
    BgDatepicker,
    BgSelect,
  },

  created() {
    this.isShowModal = this.openModal;
    if (this.storageParams) {
      this.advancedSearchParams = {
        ...this.storageParams,
      };
      this.handleCountFilter();
      this.$emit('handle-filter-count', this.countFilter);
      this.handleSelectedDates();
    }
  },

  computed: {
    storageParams() {
      return JSON.parse(sessionStorage.getItem('surveySearchFilter'));
    },
    disabledSurveyDate() {
      const maxDateRange = 120;
      const startDate = new Date(
        this.advancedSearchParams[`survey_date[start]`]
      );
      const limitDate = new Date(
        new Date(startDate).setDate(startDate.getDate() + maxDateRange)
      );
      return {
        to: startDate,
        from: limitDate,
      };
    },
    disabledUpdatedDate() {
      const maxDateRange = 120;
      const startDate = new Date(
        this.advancedSearchParams[`updated_date[start]`]
      );
      const limitDate = new Date(
        new Date(startDate).setDate(startDate.getDate() + maxDateRange)
      );
      return {
        to: startDate,
        from: limitDate,
      };
    },
  },

  watch: {
    isShowModal: {
      immediate: true,
      handler(isOpen) {
        if (!isOpen) this.$emit('close-modal');
      },
    },
    openModal: {
      immediate: true,
      handler(isOpen) {
        if (isOpen) this.isShowModal = this.openModal;
      },
    },
  },

  methods: {
    handleCountFilter() {
      let countMinus = 0;
      const allParams = {
        ...this.storageParams,
        ...this.advancedSearchParams,
      };
      this.exemptProperty.forEach(e => delete allParams[e]);
      if (allParams[`survey_date[start]`] && allParams[`survey_date[end]`]) {
        countMinus += 1;
      }
      if (allParams[`updated_date[start]`] && allParams[`updated_date[end]`]) {
        countMinus += 1;
      }
      this.countFilter = Object.keys(allParams).length - countMinus;
    },

    actionSearch() {
      const filterActionParameters = {
        ...this.storageParams,
        ...this.advancedSearchParams,
      };
      sessionStorage.setItem(
        'surveySearchFilter',
        JSON.stringify(filterActionParameters)
      );
      this.handleCountFilter();
      this.$emit('handle-reset-button');
      this.$emit('handle-filter-count', this.countFilter);
      this.handleCloseModal();
    },

    handleResetSearch() {
      this.handleResetParameters();
      this.handleCloseModal();
    },

    handleResetParameters() {
      this.filterProperties.forEach(e => {
        delete this.advancedSearchParams[e];
      });
      sessionStorage.setItem(
        'surveySearchFilter',
        JSON.stringify(this.advancedSearchParams)
      );
      this.$emit('handle-filter-count', 0);
      this.handleCountFilter();
    },

    handleCloseModal() {
      this.isShowModal = false;
      this.$emit('close-filter-modal');
    },

    handleSelectedDates() {
      if (this.storageParams[`updated_date[start]`]) {
        const endDate = new Date(this.storageParams[`updated_date[start]`]);
        this.advancedSearchParams[`updated_date[start]`] = endDate;
      }

      if (this.storageParams[`updated_date[end]`]) {
        const endDate = new Date(this.storageParams[`updated_date[end]`]);
        this.advancedSearchParams[`updated_date[end]`] = endDate;
      }

      if (this.storageParams[`survey_date[start]`]) {
        const startDate = new Date(this.storageParams[`survey_date[start]`]);
        this.advancedSearchParams[`survey_date[start]`] = startDate;
      }

      if (this.storageParams[`survey_date[end]`]) {
        const startDate = new Date(this.storageParams[`survey_date[end]`]);
        this.advancedSearchParams[`survey_date[end]`] = startDate;
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./SurveyFilterActionModal.scss"></style>

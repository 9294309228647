var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"survey-table-content-list"},[_c('table',{staticClass:"ss-table survey-table-content-list__table",attrs:{"data-testId":"tenant-table-list"}},[_c('thead',[_c('tr',_vm._l((_vm.tableHeaders),function(header,i){return _c('th',{key:i,attrs:{"aria-label":header.text}},[_c('div',{staticClass:"table-header",class:{
              'table-header__align-left': _vm.isHeaderForDate(header.text).value,
            },on:{"click":function($event){_vm.updateSortingList(_vm.isHeaderForDate(header.text), header.text)}}},[_c('bg-text',{staticClass:"table-header__text",class:{
                'table-header__date': _vm.isHeaderForDate(header.text).value,
              },attrs:{"size":"title-5"}},[_vm._v(_vm._s(header.text))]),(_vm.isHeaderForDate(header.text))?_c('span',{staticClass:"table-header__icon"},[(_vm.isHeaderForDate(header.text).isSurveyTime)?_c('bg-icon',{attrs:{"name":_vm.surveyTimeIconSortType,"size":"sm"}}):_vm._e(),(_vm.isHeaderForDate(header.text).isUpdateSurveyTime)?_c('bg-icon',{attrs:{"name":_vm.surveyUpdateIconSortType,"size":"sm"}}):_vm._e()],1):_vm._e()],1)])}),0)]),_c('tbody',[(_vm.isLoadData)?_vm._l((5),function(i){return _c('tr',{key:i},_vm._l((_vm.tableHeaders.length),function(j){return _c('td',{key:j},[_c('bg-skeleton',{attrs:{"width":"100%","height":24}})],1)}),0)}):(!_vm.isEmptyList)?_vm._l((_vm.tableContent.surveys),function(list,i){return _c('tr',{key:i,class:{
            'table-body__row-red': _vm.checkingRowNeedAction(
              list.timeline_status,
              list.status
            ),
            'table-body__row-purple': _vm.isEnableHoverEffect,
            'table-body__row-purple--active':
              _vm.isEnableHoverEffect && _vm.rowActive === i,
          },on:{"click":function($event){return _vm.handleClickRow({
              rowNumber: i,
              surveyId: list.id,
              revisionId: list.revision_id,
            })}}},[_c('td',[_c('bg-text',{attrs:{"size":"input-lg"}},[_vm._v(" "+_vm._s(list.id)+" ")])],1),(list.surveyor)?_c('td',[_c('bg-text',{attrs:{"size":"input-lg"}},[_c('bg-link',{attrs:{"tag":"router-link","to":{
                  name: 'survey-tracker.profile',
                  params: { surveyId: list.id },
                }}},[_vm._v(" "+_vm._s(list.surveyor.name)+" ")]),_c('br'),_vm._v(" "+_vm._s(list.surveyor.phone_number)+" ")],1)],1):_vm._e(),_c('td',[_c('bg-link',{attrs:{"href":_vm.getPropertyDetailOverviewPath(list.room.property_id),"target":"_blank","rel":"noopener noreferrer"},on:{"click":function($event){return _vm.showInvalidPropertyIdAlert(list.room.property_id)}}},[_vm._v(" "+_vm._s(list.room.name)+" ")])],1),_c('td',[_c('div',{staticClass:"table-body__date"},[_vm._v(" "+_vm._s(_vm.mixDateFormatterToDisplay(list.survey_datetime, 'DD/MM/YYYY'))+" "),_c('br'),_c('div',{staticClass:"table-body__date--float-right"},[_vm._v(" "+_vm._s(_vm.mixDateFormatterToDisplay(list.survey_datetime, 'HH:mm'))+" ")])])]),_c('td',[_c('bg-label-rainbow',{staticClass:"table-body__capitalize",attrs:{"color":_vm.generateLabelType(list.phase)}},[_vm._v(" "+_vm._s(list.source)+" ")])],1),_c('td',[_c('bg-label-rainbow',{staticClass:"table-body__label",attrs:{"color":_vm.generateLabelType(list.status)}},[_vm._v(" "+_vm._s(list.status)+" ")]),(list.timeline_status === 'ongoing')?_c('bg-label-rainbow',{staticClass:"table-body__label",attrs:{"color":"yellow"}},[_vm._v(" Hari H Survei ")]):_vm._e()],1),_c('td',[_c('div',{staticClass:"table-body__date"},[_vm._v(" "+_vm._s(_vm.mixDateFormatterToDisplay(list.updated_time, 'DD/MM/YYYY'))+" "),_c('br'),_c('div',{staticClass:"table-body__date--float-right"},[_vm._v(" "+_vm._s(_vm.mixDateFormatterToDisplay(list.updated_time, 'HH:mm'))+" ")])])]),_c('td',[_vm._v(_vm._s(list.updated_by))]),(_vm.isShowActionHeader)?_c('td',[_c('bg-icon',{staticClass:"table-body__action-button",attrs:{"name":"more-vertical","title":"open options for this item","data-testid":"row-action-icon"},nativeOn:{"click":function($event){return _vm.handleClickAction({ listId: list.id })}}}),(
                _vm.isShowCardAction.active &&
                _vm.isShowCardAction.actionId === list.id
              )?_c('bg-card',{staticClass:"table-body__action"},_vm._l((_vm.actions),function(action,i){return _c('bg-list-item',{key:i,attrs:{"data-testid":"row-action-button"}},[_c('div',{staticClass:"table-body__action-row",on:{"click":function($event){return action.actionClick(list)}}},[_c('bg-icon',{staticClass:"table-body__action-icon",attrs:{"name":action.icon,"size":"md"}}),(action.title === 'follow-up')?_c('bg-text',{attrs:{"size":"input-lg"}},[_vm._v(_vm._s(_vm.generateFollowUpStatus(list.handled))+" ")]):_c('bg-text',{attrs:{"size":"input-lg"}},[_vm._v(_vm._s(action.title))])],1)])}),1):_vm._e()],1):_vm._e()])}):_vm._e()],2)]),(!_vm.isLoadData && !_vm.isEmptyList)?_c('bg-pagination',{attrs:{"page-total":_vm.totalPage},on:{"click":_vm.handleChangePage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e(),_c('survey-form-update-modal',{attrs:{"open-modal":_vm.isOpenModal,"auto-fill-data":_vm.rowSelectedData},on:{"close-modal":_vm.closeModalUpdateSurvey,"on-update-success":_vm.handleChangePage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="survey-tenant">
    <bg-text size="heading-3" class="survey-tenant__title"
      >Survey Tracker</bg-text
    >
    <survey-top-action-bar @on-search-filter="handleSearchFilter" />
    <survey-secondary-bar
      :is-load-update="isLoadData"
      :total-result="listTotalResults"
      :total-list="totalListData"
      @on-survey-created="getDataSurveyList"
    />
    <survey-table-content-list
      :table-content="listTrackerSurvey"
      :is-load-data="isLoadData"
      @on-click-page="updateSurveyDataBasedPage"
      @on-click-sort-survey-time="handleSortSurveyTime"
      @on-click-sort-update-survey="handleSortUpdateSurveyTime"
    />
    <bg-empty-state
      v-if="!isLoadData && listTotalResults === 0"
      title="Data Tidak Ditemukan"
      illustration-name="login-failed"
      illustration-alt="Data tidak ditemukan"
      description="Mohon periksa kembali kata kunci yang Anda masukkan"
    />
  </div>
</template>

<script>
import { BgText, BgEmptyState } from 'bangul-vue';
import TrackerSurveyApi from '@admin_endpoints/survey-tracker';
import SurveyTopActionBar from './components/SurveyTopActionBar';
import SurveySecondaryBar from './components/SurveySecondaryBar';
import SurveyTableContentList from './components/SurveyTableContentList';

export default {
  name: 'SurveyTracker',

  data() {
    return {
      isLoadData: false,
      currentPage: 1,
      limitPage: 10,
      listTrackerSurvey: [],
      sortSurveyType: {},
    };
  },

  components: {
    BgText,
    BgEmptyState,
    SurveyTopActionBar,
    SurveySecondaryBar,
    SurveyTableContentList,
  },

  created() {
    this.getDataSurveyList();
  },

  computed: {
    listTotalResults() {
      if (!this.listTrackerSurvey?.pagination) return 0;

      const calculateResult = this.currentPage * this.limitPage;
      const isLastPageNotFull =
        this.listTrackerSurvey.pagination.total - calculateResult < 0;
      const isResultFullPage =
        this.listTrackerSurvey.pagination.offset % this.limitPage === 0;

      return isResultFullPage && !isLastPageNotFull
        ? calculateResult
        : this.listTrackerSurvey?.pagination?.total;
    },

    totalListData() {
      return this.listTrackerSurvey?.pagination?.total || 0;
    },
  },

  methods: {
    updateSurveyDataBasedPage(params) {
      const { query, page } = params;
      this.currentPage = page;
      this.getDataSurveyList(query);
    },

    handleSortSurveyTime(sortType) {
      this.sortSurveyType = {
        sort_by: 'survey_time',
        sort_type: sortType,
      };
      this.getDataSurveyList();
    },

    handleSearchFilter(params) {
      sessionStorage.setItem('surveySearchFilter', JSON.stringify(params));
      this.getDataSurveyList(params, true);
    },

    handleSortUpdateSurveyTime(sortType) {
      this.sortSurveyType = {
        sort_by: 'updated_time',
        sort_type: sortType,
      };
      this.getDataSurveyList();
    },

    async getDataSurveyList(query = {}, isSearch) {
      const offsetPage = isSearch
        ? 0
        : (+this.currentPage - 1) * this.limitPage;

      try {
        this.isLoadData = true;
        const params = {
          ...this.sortSurveyType,
          ...query,
          offset: offsetPage,
          limit: this.limitPage,
        };
        const res = await TrackerSurveyApi.getSurveyTrackerList(params);
        this.listTrackerSurvey = res.data;
      } catch (error) {
        console.error(error);
        alert(error.response.data?.message);
      } finally {
        this.isLoadData = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./SurveyTracker.scss"></style>

import api from '@admin/api/service';

export default {
  getSurveyTrackerList(params) {
    const queryString = new URLSearchParams(params).toString();
    return api.get(`/singgahsini/api/tenant-survey?${queryString}`);
  },

  updateDataSurveyTracker(rowId, params) {
    return api.put(`/singgahsini/api/tenant-survey/${rowId}`, params);
  },

  postSurveyTracker(params) {
    return api.post(`/singgahsini/api/tenant-survey`, params);
  },

  getSurveyTrackerByUser(surveyId, params) {
    const queryString = new URLSearchParams(params).toString();
    return api.get(
      `/singgahsini/api/tenant-survey/${surveyId}/history?${queryString}`
    );
  },

  getSurveyTrackerDetail(params) {
    const { surveyId, revisionId } = params;
    return api.get(
      `/singgahsini/api/tenant-survey/${surveyId}/history/${revisionId}`
    );
  },
};

/* eslint-disable */

/**
 *
 * @param {Function} func This function that will be delay
 * @param {Function|Number} wait If use Function type you have to return it as a number
 * @param {Function} immediateFunc This function will be call first before first function param (no delay, recall whenever debounce function was executed)
 * @returns {Void}
 */
export default function (func, wait, immediateFunc) {
  let timeout;

  return function () {
    let $this = this;
    let args = arguments;
    let waitingTime = typeof wait === 'function' ? wait.apply($this) : wait;

    clearTimeout(timeout);

    if (typeof immediateFunc === 'function') immediateFunc.apply($this, args);

    timeout = setTimeout(function () {
      timeout = null;

      func.apply($this, args);
    }, waitingTime);
  };
}
/* eslint-enable */

<template>
  <div class="loader" :class="{ loader__full: type === 'card' }">
    <div v-if="type === 'table'">
      <table class="ss-table">
        <thead>
          <tr v-for="i in row" :key="`loading-tr-${i}`">
            <td v-for="(_column, index) in columns" :key="index">
              <bg-skeleton :height="50" width="100%" />
            </td>
          </tr>
        </thead>
      </table>
    </div>
    <div v-if="type === 'profile'">
      <div class="loader__title">
        <bg-skeleton
          :height="40"
          width="30%"
          class="loader__title--space-bottom"
        />
        <bg-skeleton :height="150" width="100%" />
      </div>
      <div class="loader__title">
        <bg-skeleton :height="45" width="35%" />
        <bg-skeleton
          :height="20"
          width="70%"
          class="loader__title--space-top"
        />
      </div>
      <div class="loader__action">
        <bg-skeleton :height="50" width="40%" />
        <bg-skeleton :height="50" width="25%" />
        <bg-skeleton :height="50" width="25%" />
      </div>
      <table class="ss-table">
        <thead>
          <tr v-for="i in 4" :key="`loading-tr-${i}`">
            <td v-for="(column, index) in columns" :key="index">
              <bg-skeleton :height="30" width="100%" />
            </td>
          </tr>
        </thead>
      </table>
    </div>
    <div v-if="type === 'card'">
      <div class="loader__card">
        <bg-skeleton :height="30" width="55%" />
        <bg-skeleton :height="30" width="30%" />
      </div>
      <bg-skeleton :height="40" width="55%" class="loader__title--space-top" />
      <div class="loader__card">
        <bg-skeleton :height="30" width="70%" />
        <bg-skeleton :height="60" width="25%" />
      </div>
      <bg-skeleton :height="20" width="90%" class="loader__title--space-top" />
      <div
        v-for="i in 4"
        :key="i"
        class="loader__card loader__title--space-top"
      >
        <bg-skeleton :height="30" width="60%" />
        <bg-skeleton :height="30" width="30%" />
      </div>
    </div>
  </div>
</template>

<script>
import { BgSkeleton } from 'bangul-vue';

export default {
  name: 'SkeletonLoader',

  components: {
    BgSkeleton,
  },

  props: {
    columns: {
      type: [Number, Array],
      default: 4,
    },
    row: {
      type: [Number, Array],
      default: 4,
    },

    type: {
      type: String,
      default: 'table',
    },
  },
};
</script>
<style lang="scss" scoped src="./SkeletonLoader.scss"></style>

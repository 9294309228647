<template>
  <div class="secondary-bar">
    <div v-if="isLoadUpdate" class="secondary-bar__result">
      <bg-skeleton width="30%" :height="38" />
      <bg-skeleton width="15%" :height="38" class="secondary-bar__add-survey" />
    </div>

    <div v-else class="secondary-bar__result">
      <bg-text size="input-lg" class="secondary-bar__result-text"
        >Menampilkan {{ totalResult }} dari {{ totalList }} riwayat</bg-text
      >
      <div class="secondary-bar__add-survey">
        <bg-button-icon icon="add-plus" @click="isOpenModal = true"
          >Tambah Data Survey</bg-button-icon
        >
      </div>
    </div>

    <survey-form-update-modal
      :open-modal="isOpenModal"
      title="Tambah Data Survei"
      subtitle="Silakan masukkan data survei yang ingin ditambahkan."
      @close-modal="isOpenModal = false"
      @on-survey-created="handleSurveyCreated"
    />
  </div>
</template>

<script>
import { BgButtonIcon, BgSkeleton, BgText } from 'bangul-vue';
import SurveyFormUpdateModal from '../SurveyFormUpdateModal';

export default {
  name: 'SurveySecondaryBar',

  data() {
    return {
      isOpenModal: false,
    };
  },

  props: {
    isLoadUpdate: {
      type: Boolean,
      default: false,
    },

    totalResult: {
      type: Number,
      default: 0,
    },
    totalList: {
      type: Number,
      default: 0,
    },
  },

  components: {
    SurveyFormUpdateModal,
    BgSkeleton,
    BgButtonIcon,
    BgText,
  },

  methods: {
    handleSurveyCreated() {
      this.$emit('on-survey-created');
    },
  },
};
</script>

<style lang="scss" scoped src="./SurveySecondaryBar.scss"></style>
